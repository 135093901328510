html,
body {
    font-family: 'Montserrat' !important;
    height: 100%;
}
p {
    font-family: 'Montserrat', sans-serif;
}
::-webkit-scrollbar {
    display: none;
}
::-webkit-inner-spin-button {
    display: none;
}
.hidden {
    opacity: 0;
}
